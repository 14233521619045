/**
 * @generated SignedSource<<7ded88cd01b23eabbcb18afd45462146>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentClass = "CONTENT_CLASS_FEATURE_ANNOUNCEMENT_MODAL" | "CONTENT_CLASS_MODAL" | "CONTENT_CLASS_NEXT_BEST_ACTIONS" | "CONTENT_CLASS_UNKNOWN" | "%future added value";
export type PreferenceType = "PREFERENCE_TYPE_ORDERING" | "PREFERENCE_TYPE_UNKNOWN" | "PREFERENCE_TYPE_VISIBILITY" | "%future added value";
export type FindContentSettingQuery$variables = {
  companyId: string;
  objectClass: ContentClass;
  objectId?: string | null;
  preferenceType: PreferenceType;
  userId: string;
};
export type FindContentSettingQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FindContentSettingFragment_query">;
};
export type FindContentSettingQuery = {
  response: FindContentSettingQuery$data;
  variables: FindContentSettingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectClass"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "preferenceType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FindContentSettingQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FindContentSettingFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "FindContentSettingQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          },
          {
            "kind": "Variable",
            "name": "objectClass",
            "variableName": "objectClass"
          },
          {
            "kind": "Variable",
            "name": "objectId",
            "variableName": "objectId"
          },
          {
            "kind": "Variable",
            "name": "preferenceType",
            "variableName": "preferenceType"
          },
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "concreteType": "FindSpecificUserContentSettingConnection",
        "kind": "LinkedField",
        "name": "contentSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContentSetting",
            "kind": "LinkedField",
            "name": "contentSetting",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectClass",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preferenceType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preferenceValue",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "986d7a99b877d3cf7c298e36ffb94efc",
    "id": null,
    "metadata": {},
    "name": "FindContentSettingQuery",
    "operationKind": "query",
    "text": "query FindContentSettingQuery(\n  $userId: ID!\n  $companyId: ID!\n  $objectClass: ContentClass!\n  $objectId: String\n  $preferenceType: PreferenceType!\n) {\n  ...FindContentSettingFragment_query\n}\n\nfragment FindContentSettingFragment_query on Query {\n  contentSetting(objectClass: $objectClass, objectId: $objectId, userId: $userId, companyId: $companyId, preferenceType: $preferenceType) {\n    contentSetting {\n      id\n      userId\n      companyId\n      objectId\n      objectClass\n      preferenceType\n      preferenceValue\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "797b5624021993528b61a237abea6afc";

export default node;
