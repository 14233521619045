export enum ContentClass {
  MODAL = 'CONTENT_CLASS_MODAL',
  FEATURE_ANNOUNCEMENT_MODAL = 'CONTENT_CLASS_FEATURE_ANNOUNCEMENT_MODAL',
  NEXT_BEST_ACTIONS = 'CONTENT_CLASS_NEXT_BEST_ACTIONS',
}

export enum AnnouncementModalContentIds {
  ANNOUNCEMENT_MODAL_DISMISSAL_DEMO = 'ANNOUNCEMENT_MODAL_DISMISSAL_DEMO',
}

export enum NextBestActionsContentIds {
  NEXT_BEST_ACTIONS_ORDERING_IDS = 'NEXT_BEST_ACTIONS_ORDERING_IDS',
  NEXT_BEST_ACTIONS_ORDERING_TIMESTAMP = 'NEXT_BEST_ACTIONS_ORDERING_TIMESTAMP',
}

export enum PreferenceType {
  VISIBILITY = 'PREFERENCE_TYPE_VISIBILITY',
  ORDERING = 'PREFERENCE_TYPE_ORDERING',
}
