import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
import ObjectDismissedQuery, {
  ContentClass,
  ObjectDismissedQuery as ObjectDismisedQueryType,
} from './__generated__/ObjectDismissedQuery.graphql';
import ObjectDismissedFragment_query, {
  ObjectDismissedFragment_query$key,
} from './__generated__/ObjectDismissedFragment_query.graphql';
import UserContentSettingMutation, {
  UserContentSettingMutation as UserContentSettingMutationType,
  UserContentSettingMutation$data,
  PreferenceType,
} from './__generated__/UserContentSettingMutation.graphql';
import FindContentSettingQuery, {
  FindContentSettingQuery as FindContentSettingQueryType,
} from './__generated__/FindContentSettingQuery.graphql';
import FindContentSettingFragment_query, {
  FindContentSettingFragment_query$key,
} from './__generated__/FindContentSettingFragment_query.graphql';
import { DatadogLogger, useCurrentUser } from '@attentive/acore-utils';

export type UserContentMutationArgs = {
  contentClass: ContentClass;
  contentId: string;
  preferenceType: PreferenceType;
  preferenceValue: string;
  onCompleted?: (response: UserContentSettingMutation$data) => void;
};

const logger = new DatadogLogger({ libName: 'content-visibility' });

export const useIsContentVisibleForUser = (contentClass: ContentClass, contentId: string) => {
  const {
    id: userId,
    company: { id: companyId },
  } = useCurrentUser();

  const data = useLazyLoadQuery<ObjectDismisedQueryType>(ObjectDismissedQuery, {
    userId,
    companyId,
    contentClass,
    contentId,
  });

  const { contentVisibility } = useFragment<ObjectDismissedFragment_query$key>(
    ObjectDismissedFragment_query,
    data
  );

  return contentVisibility?.isVisible;
};

export const useCreateContentSetting = () => {
  const [insertUserContentSettingMutation] = useMutation<UserContentSettingMutationType>(
    UserContentSettingMutation
  );

  const {
    id: userId,
    company: { id: companyId },
  } = useCurrentUser();

  return async ({
    contentClass,
    contentId,
    preferenceType,
    preferenceValue,
    onCompleted,
  }: UserContentMutationArgs) => {
    insertUserContentSettingMutation({
      variables: {
        input: {
          userId,
          companyId,
          objectClass: contentClass,
          objectId: contentId,
          preferenceType,
          preferenceValue,
        },
      },
      onCompleted: (resp: UserContentSettingMutation$data) => {
        if (onCompleted) {
          onCompleted(resp);
        }
      },
      onError: (error) => {
        logger.logLibError(error);
      },
    });
  };
};

export const useFindContentSetting = (
  objectClass: ContentClass,
  objectId: string,
  preferenceType: PreferenceType
) => {
  const {
    id: userId,
    company: { id: companyId },
  } = useCurrentUser();

  const data = useLazyLoadQuery<FindContentSettingQueryType>(FindContentSettingQuery, {
    userId,
    companyId,
    objectClass,
    objectId,
    preferenceType,
  });

  const { contentSetting } = useFragment<FindContentSettingFragment_query$key>(
    FindContentSettingFragment_query,
    data
  );

  return contentSetting?.contentSetting.preferenceValue;
};
