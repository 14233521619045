/**
 * @generated SignedSource<<ab7029393062a32b57f9767c3c231352>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContentClass = "CONTENT_CLASS_FEATURE_ANNOUNCEMENT_MODAL" | "CONTENT_CLASS_MODAL" | "CONTENT_CLASS_NEXT_BEST_ACTIONS" | "CONTENT_CLASS_UNKNOWN" | "%future added value";
export type PreferenceType = "PREFERENCE_TYPE_ORDERING" | "PREFERENCE_TYPE_UNKNOWN" | "PREFERENCE_TYPE_VISIBILITY" | "%future added value";
export type CreateOrUpdateUserContentSettingInput = {
  companyId?: string | null;
  objectClass: ContentClass;
  objectId?: string | null;
  preferenceType: PreferenceType;
  preferenceValue: string;
  userId?: string | null;
};
export type UserContentSettingMutation$variables = {
  input: CreateOrUpdateUserContentSettingInput;
};
export type UserContentSettingMutation$data = {
  readonly createOrUpdateUserContentSetting: {
    readonly preferenceId: string;
  } | null;
};
export type UserContentSettingMutation$rawResponse = {
  readonly createOrUpdateUserContentSetting: {
    readonly preferenceId: string;
  } | null;
};
export type UserContentSettingMutation = {
  rawResponse: UserContentSettingMutation$rawResponse;
  response: UserContentSettingMutation$data;
  variables: UserContentSettingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOrUpdateUserContentSettingPayload",
    "kind": "LinkedField",
    "name": "createOrUpdateUserContentSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "preferenceId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserContentSettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserContentSettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a4169dab7abf0d5175fde233aa742657",
    "id": null,
    "metadata": {},
    "name": "UserContentSettingMutation",
    "operationKind": "mutation",
    "text": "mutation UserContentSettingMutation(\n  $input: CreateOrUpdateUserContentSettingInput!\n) {\n  createOrUpdateUserContentSetting(input: $input) {\n    preferenceId\n  }\n}\n"
  }
};
})();

(node as any).hash = "d92e879aef8d7e9651ba2b22b5f62fc9";

export default node;
